<template>
	<div id="state-code">
		<div class="language-picker">
			<ul>
				<li v-for="(languageName, languageCode) in $store.state.i18n.available"
					:key="languageCode"
					:class="{active: $store.getters.isCurrentLanguage(languageCode)}"
					@click="$store.dispatch('setLanguage', languageCode)"
				>{{ languageName }}</li>
			</ul>
		</div>

		<form @submit.prevent="submit()">

			<phi-input v-model="inputCode" :label="$t('StateCode.accessCode')" @input="error = false" autocorrect="off" autocapitalize="off" spellcheck="false"></phi-input>

			<footer>
				<button class="phi-button phi-main" :class="{danger: !!error}" :disabled="!inputCode.trim()">
					<span v-show="!isLoading && !error">{{ $t('StateCode.Continue') }}</span>
					<span v-show="isLoading" class="loading">
						<i class="fa fa-spinner fa-spin"></i>
						{{ $t('StateCode.Searching') }}
					</span>
					<span v-show="error">{{ $t('StateCode.CodeNotFound') }}</span>
				</button>
			</footer>

			<a id="toggle-dialog" @click="dialogShown = true">{{ $t('StateCode.WhatIsThis') }}</a>
		</form>

		<mu-dialog id="dialog" :open="dialogShown" @close="dialogShown = false">
			<div v-html="$t('StateCode.dialog')"></div>
			<mu-flat-button slot="actions" @click="dialogShown = false" primary :label="$t('StateCode.OK')" />
		</mu-dialog>
	</div>
</template>

<script>
import Client from '@/libraries/phidias.js/lib/Client/Client.js';
import PhiInput from '../components/Phi/Input.vue';

export default {
	data() {
		return {
			inputCode: "",
			isLoading: false,
			error: false,
			dialogShown: false
		}
	},

	methods: {
		submit() {
			this.isLoading = true;

			/* If the code is a URL, set it directly */
			if (this.inputCode.substring(0, 4) == "http") {
				this.$store.dispatch("setUrl", this.inputCode)
					.then(() => this.$router.push("login"))
					// .catch(() => this.error = true)
					.finally(() => this.isLoading = false);

				return;
			}

			/* Otherwise, fetch code from rack service */
			let rackUrl = "https://phidias.io/";

			new Client(rackUrl)
				.get(`/code/${this.inputCode}`)
				.then(response => this.$store.dispatch("setUrl", response.url))
				.then(() => this.$router.push("login"))
				.catch(() => this.error = true)
				.finally(() => this.isLoading = false);
		}
	},

	components: {
		PhiInput
	},

	i18n: {
		"de": {
			"StateCode.accessCode": "Zugangscode",
			"StateCode.Continue": "fortsetzen",
			"StateCode.Searching": "Suche",
			"StateCode.CodeNotFound": "Code wurde nicht gefunden",
			"StateCode.WhatIsThis": "Was ist das ?",
			"StateCode.OK": "OK",
			"StateCode.dialog": `
				<h2>Zugangscode</h2>
				<p>Diese App funktioniert ausschließlich für Schulen, die <strong>Phidias</strong> benutzen.</p>
				<p>Kontaktieren Sie bitte Ihre Schule, um einen gültigen Zugangscode zu erhalten.</p>
			`
		},

		"en": {
			"StateCode.accessCode": "access code",
			"StateCode.Continue": "Continue",
			"StateCode.Searching": "Searching",
			"StateCode.CodeNotFound": "Code not found",
			"StateCode.WhatIsThis": "What is this ?",
			"StateCode.OK": "OK",
			"StateCode.dialog": `
				<h2>Access code</h2>
				<p>This App works exclusively for schools registered with <strong>Phidias</strong>.</p>
				<p>Get in touch with your school to obtain a valid access code.</p>
			`
		},

		"es": {
			"StateCode.accessCode": "código de acceso",
			"StateCode.Continue": "Continuar",
			"StateCode.Searching": "Buscando",
			"StateCode.CodeNotFound": "Código no encontrado",
			"StateCode.WhatIsThis": "Qué es esto ?",
			"StateCode.OK": "OK",
			"StateCode.dialog": `
				<h2>Código de acceso</h2>
				<p>Esta App funciona exclusivamente para colegios que utilizan el sistema <strong>Phidias</strong>.</p>
				<p>Comunícate con tu colegio para obtener tu código de acceso.</p>
			`
		},

		"fr": {
			"StateCode.accessCode": "code d'accès",
			"StateCode.Continue": "continuer",
			"StateCode.Searching": "recherche",
			"StateCode.CodeNotFound": "Code non trouvé",
			"StateCode.WhatIsThis": "Qu'est ce que c'est ?",
			"StateCode.OK": "OK",
			"StateCode.dialog": `
				<h2>code d'accès</h2>
				<p>Cette App fonctionne pour les écoles enregistrées exclusivement avec <strong>Phidias</strong>.</p>
				<p>Poure obtenir un code d'accès valide, contactez votre école.</p>
			`
		},

		"it": {
			"StateCode.accessCode": "codice di accesso",
			"StateCode.Continue": "continua",
			"StateCode.Searching": "ricerca",
			"StateCode.CodeNotFound": "Codice non trovato",
			"StateCode.WhatIsThis": "Cos'è questo?",
			"StateCode.OK": "OK",
			"StateCode.dialog": `
				<h2>Codice di accesso</h2>
				<p> Questa app funziona esclusivamente per le scuole che usano il sistema <strong> Phidias </ strong>. </ p>
				<p> Contatta la tua scuola per ottenere il tuo codice di accesso. </ p>
			`
		}
	}
}
</script>

<style lang="scss">
#state-code {
	text-align: center;
	height: 100%;
	background-color: #000;
	overflow: auto; /* prevents margin collapse (i.e. form's top margin is not translated to this parent) */

	.phi-input {
		display: block;
    	color: #fff;
	}

	form {
		width: 270px;
		margin: auto;
		margin-top: 76px;
		background: url('../assets/phidias-label.png') no-repeat center top;
		padding-top: 64px + 48px;

		footer {
			margin: 48px 0;
		}
	}

	#toggle-dialog {
		display: block;
		color: #fff;
		opacity: 0.8;
		cursor: pointer;
		padding: 8px 12px;
		padding-top: 32px;
	}

	.language-picker {
		color: #fff;

		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			display: flex;
			align-items: center;

			li {
				cursor: pointer;
				font-size: 0.8em;
				flex: 1;
				padding: 12px;
				opacity: .5;

				&.active {
					opacity: 1;
					font-weight: bold;
				}
			}
		}
	}
}

#dialog .dialog-container {
	text-align: left;
	padding: 12px 24px;

	h2 {
		margin: 0;
		margin-bottom: 1em;
	}

	p {
		margin: 0;
	}

	strong {
		font-weight: bold;
	}

	.buttons {
		margin-top: 24px;
		text-align: right;
	}
}
</style>